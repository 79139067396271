<template>
  <div class="tableView" ref="tableCot">
    <!-- max-height="450px" -->
    <el-table
      ref="multipleTable"
      element-loading-text="数据加载中..."
      v-loading="loading"
      border
      :data="data1"
      class="table"
      @selection-change="handleSelectionChange"
      :max-height="maxHeight"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="check"
        :selectable="isSelectable ? selectable : noFn"
      >
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        width="80"
        align="center"
        v-if="Indexes"
      ></el-table-column>
      <el-table-column
        :show-overflow-tooltip="isShowOverflow"
        align="left"
        header-align="left"
        :key="idx"
        :prop="item.prop"
        :label="item.label"
        v-for="(item, idx) of columns1"
        :width="item.width"
        :type="item.type"
        :fixed="item.fixed"
      >
        <template slot-scope="scope">
          <ex-slot
            v-if="item.render"
            :render="item.render"
            :row="scope.row"
            :index="scope.$index"
            :column="item"
          >
          </ex-slot>
          <span v-else>
            {{ scope.row[item.prop] | firstFilter(scope.row[item.prop]) }}
          </span>
        </template>
      </el-table-column>

      <template slot="empty" class="emptyTem">
        <img :src="emptyImg" alt="" srcset="" />
        <div>暂无数据</div>
      </template>
    </el-table>
    <div class="page" style="text-align: center">
      <pagination
        small="true"
        :total="total"
        :page.sync="listInterfaceQuery.page"
        :limit.sync="listInterfaceQuery.size"
        align="right"
        @pagination="getAppInterfaceList"
      />
    </div>

    <p>{{ logo }}</p>
  </div>
</template>

<script>
import Pagination from './Pagination.vue'

var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,

    column: {
      type: Object,
      default: null
    }
  },
  render: (h, data) => {
    const params = {
      row: data.props.row,
      index: data.props.index
    }
    if (data.props.column) params.column = data.props.column
    return data.props.render(h, params)
  }
}

export default {
  components: {
    'ex-slot': exSlot,
    Pagination
  },
  data() {
    return {
      value: '123',
      emptyImg: require('../assets/images/empty.png'),
      tableShow: {
        currentPage: 1,
        pageSize: 10,
        listCount: ''
      },
      listInterfaceQuery: {
        page: 1, // 当前页码
        size: 10 // 每页显示条数
      },
      modal: false,

      single: false,
      columns: [],
      data: []
    }
  },
  filters: {
    firstFilter(val) {
      if (val === '' || val === null) {
        return '-'
      } else {
        return val
      }
    }
  },

  methods: {
    changeSize(size) {
      this.tableShow.pageSize = size
    },
    checkChange(selection, row) {
      this.$emit('checkChange', selection)
    },
    getAppInterfaceList(val) {
      this.$emit('getAjax', val)
    },
    handleSelectionChange(val) {
      this.$emit('selectionChange', val)
    },
    toggleSelection(obj, bol) {
      this.$refs.multipleTable.toggleRowSelection(obj, bol)
    },
    selectable(row) {
      return row.zt == 1
    },
    noFn() {
      return true
    }
  },
  created() {},
  mounted() {
    // this.data = this.data1;
  },
  props: {
    logo: {},
    data1: {
      type: Array
    },
    columns1: {},
    total: {},
    Indexes: {
      type: Boolean,
      default: true
    },
    check: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    page: null,
    isShowOverflow: {
      type: Boolean,
      default: true
    },
    maxHeight:{
      type: Number
    },
    size: null
  },
  watch: {
    data1: function () {
      this.data = this.data1
      this.columns = this.columns1
    },
    page(newVal) {
      if (newVal == 1) {
        this.listInterfaceQuery.page = 1
      }
    },
    size(newVal) {
      if (newVal) {
        this.listInterfaceQuery.size = newVal
      }
    }
  }
}
</script>

<style>
.table {
  /* margin-top: 20px; */
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.table th {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}

.el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 0px;
}

.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.el-table__body-wrapper::-webkit-scrollbar-thumb {
  margin-right: 1px;
  background: hsla(0, 0%, 70.6%, 0.4);
  background-clip: padding-box;
  min-height: 30px;
  border-radius: 20px;
}
.el-table__empty-block {
  min-height: 300px;
}
.el-table__empty-text {
  line-height: 30px;
}
.table-fixed .el-table__fixed-right,
.table-fixed .el-table__fixed {
  /* height: 100% !important; */
}
</style>
