import service from '../../until/api'

/**
 * @desc 分页查询云平台全部的版本信息
 * @param {Object} data 分页查询数据
 * @returns 
 */
export function _CouldFindAll(data) {
  return service({
    url: '/system-nacos/platformVersionManagement/findAllPage',
    method: 'GET',
    params: data
  })
}

/**
 * @desc 云平台的版本信息添加
 * @param {Object} data 云平台添加表单
 * @returns 
 */
export function _CouldVersionAdd(data) {
  return service({
    url: '/system-nacos/platformVersionManagement/add',
    method: 'POST',
    data
  })
}

/**
 * @desc 云平台版本删除
 * @param {Object} data 删除版本ID
 * @returns 
 */
export function _CouldVersionDelete(data) {
  return service({
    url: '/system-nacos/platformVersionManagement/delete',
    method: 'GET',
    params: data
  })
}

/**
 * @desc 云平台的版本信息编辑
 * @param {Object} data 云平台编辑表单
 * @returns 
 */
export function _CouldVersionUpdate(data) {
  return service({
    url: '/system-nacos/platformVersionManagement/update',
    method: 'POST',
    data
  })
}

/**
 * @desc 分页查询自动驾驶全部的版本信息
 * @param {Object} data 分页查询数据
 * @returns 
 */
export function _AutoFindAll(data) {
  return service({
    url: '/system-nacos/autoDriverVersionManagementController/findAllPage',
    method: 'GET',
    params: data
  })
}

/**
* @desc 自动驾驶的版本信息添加
* @param {Object} data 版本信息添加表单
* @returns 
*/
export function _AutoVersionAdd(data) {
  return service({
    url: '/system-nacos/autoDriverVersionManagementController/add',
    method: 'POST',
    data
  })
}

/**
 * @desc 自动驾驶版本删除
 * @param {Object} data 删除版本ID
 * @returns 
 */
export function _AutoVersionDelete(data) {
  return service({
    url: '/system-nacos/autoDriverVersionManagementController/delete',
    method: 'GET',
    params: data
  })
}

/**
 * @desc 不启用，自动驾驶的版本信息编辑
 * @param {Object} data 自动驾驶编辑表单
 * @returns 
 */
export function _AutoVersionUpdate(data) {
  return service({
    url: '/system-nacos/autoDriverVersionManagementController/update',
    method: 'POST',
    data
  })
}

/**
 * @desc 版本文件上传
 * @param {Object} data 版本文件-单个
 * @returns 
 */
export function _FileupLoad(data) {
  return service({
    url: '/system-nacos/platformVersionManagement/upload',
    method: 'POST',
    data,
    headers: { "content-type": "multipart/form-data" }
  })
}

/**
 * @desc 版本文件下载
 * @param {Object} data 版本文件-单个
 * @returns 
 */
 export function _Filedownload(data) {
  return service({
    url: '/system-nacos/platformVersionManagement/download',
    method: 'GET',
    params: data,
    responseType: 'blob'
  })
}