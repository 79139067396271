<template>
  <div>
    <!-- 标签页和头部搜索 -->
    <div class="headSearch">
      <el-tabs type="card" @tab-click="changeTabs" :value="currentPanel">
        <el-tab-pane label="云平台" name="cloud">
          <el-form :inline="true">
            <el-form-item>
              <el-select
                placeholder="产品端"
                class="centerSelInput"
                v-model="list.productSide"
              >
                <el-option
                  v-for="item in productlist"
                  :key="item.value"
                  :label="item.product"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                placeholder="产品类型"
                class="centerSelInput"
                v-model="list.productType"
              >
                <el-option
                  v-for="item in producttypelist"
                  :key="item.value"
                  :label="item.producttype"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <input
                type="button"
                class="centerCzbtn ml20"
                @click="getTableData(searchPage)"
                value="搜索"
              />
            </el-form-item>
            <el-form-item>
              <input
                type="button"
                class="centerBtn resetbtn"
                @click="reset"
                value="重置"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                class="centerBtn mb20"
                @click="(cloud_drawer = true), (filelistshow = true)"
              >
                上传版本
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="自动驾驶" name="auto">
          <el-form :inline="true">
            <el-form-item>
              <el-cascader
                clearable
                v-model="selectCarType"
                class="centerSelInput centerInput"
                :options="carTypeOption"
                :props="carTypeOptionParams"
                :show-all-levels="false"
                placeholder="请选择车辆类型"
              ></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <!-- <button
                class="centerCzbtn ml20"
                @click="getTableData(searchPage)"
              >
                搜索
              </button> -->
              <input
                type="button"
                class="centerCzbtn ml20"
                @click="getTableData(searchPage)"
                value="搜索"
              />
            </el-form-item>
            <el-form-item>
              <!-- <button class="centerBtn resetbtn" @click="reset">重置</button> -->
              <input
                type="button"
                class="centerBtn resetbtn"
                @click="reset"
                value="重置"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                class="centerBtn mb20"
                @click="(auto_drawer = true), (filelistshow = true)"
              >
                上传版本
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 表格 -->
    <Table
      :data1="tableData"
      :columns1="currentPanel == 'cloud' ? cloud_columns : auto_columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="list.page"
      :size="list.size"
      :isShowOverflow="false"
    />
    <!-- 产品添加编辑版本 -->
    <el-drawer
      :visible.sync="cloud_drawer"
      :title="isAdd ? '添加版本' : '编辑版本'"
      @close="drawClose"
      direction="rtl"
      custom-calss="demo-drawer"
      ref="cloud_drawer"
      destroy-on-close
      :wrapperClosable="isAdd"
      :before-close="CheckFile"
    >
      <div class="demo-drawer__content">
        <el-form :model="cloud_form" :rules="rules" ref="cloud_form" show-message>
          <el-form-item label="产品端" label-width="100px" prop="productSide">
            <el-select
              placeholder="请选择"
              class="centerCzSelInput"
              v-model="cloud_form.productSide"
              :props="{ value: 'value', label: 'product' }"
            >
              <el-option
                v-for="item in productlist"
                :key="item.value"
                :label="item.product"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品类型" label-width="100px" prop="productType">
            <el-select
              placeholder="请选择"
              class="centerCzSelInput"
              v-model="cloud_form.productType"
              :props="{ value: 'value', label: 'producttype' }"
            >
              <el-option
                v-for="item in producttypelist"
                :key="item.value"
                :label="item.producttype"
                :value="item.value"
                :disabled="item.value == 3 && isApp"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="版本号" label-width="100px" prop="versionNo">
            <el-input
              v-model="cloud_form.versionNo"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="版本内容" label-width="100px" prop="versionContent">
            <el-input
              v-model="cloud_form.versionContent"
              type="textarea"
              :autosize="{ minRows: 10, maxRows: 20 }"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <!-- :label="filelistshow?'文件上传':'文件修改'" -->
          <el-form-item label="文件上传" label-width="100px" :required="isApp">
            <el-button class="centerBtn ml20" @click="FileUpload = true"
              >选择文件</el-button
            >
            <div>
              <el-upload :file-list="fileList" action="#" :on-remove="removeFile">
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button
          class="centerCzbtn mr20"
          type="button"
          @click="operation"
          :loading="AddLoading"
        >
          {{ isAdd ? "确认添加" : "确认修改" }}
        </el-button>
        <button class="centerBtn resetbtn" @click="CheckFile">取消</button>
      </div>
    </el-drawer>
    <!-- 车辆添加编辑版本 -->
    <el-drawer
      :visible.sync="auto_drawer"
      :title="isAdd ? '添加版本' : '编辑版本'"
      @close="drawClose"
      direction="rtl"
      custom-calss="demo-drawer"
      ref="auto_drawer"
      destroy-on-close
      :wrapperClosable="isAdd"
      :before-close="CheckFile"
    >
      <div class="demo-drawer__content">
        <el-form :model="auto_form" :rules="rules" ref="auto_form" show-message>
          <el-form-item label="车辆子类型" label-width="100px" prop="carTypeArr">
            <el-cascader
              v-model="auto_form.carTypeArr"
              :options="carSubType"
              class="centerCzSelInput"
              :show-all-levels="false"
              :props="{
                children: 'children',
                label: 'typeName',
                value: 'id',
                checkStrictly: true,
              }"
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="版本号" label-width="100px" prop="versionNo">
            <el-input
              v-model="auto_form.versionNo"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="版本内容" label-width="100px" prop="versionContent">
            <el-input
              v-model="auto_form.versionContent"
              type="textarea"
              :autosize="{ minRows: 10, maxRows: 20 }"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="文件上传" label-width="100px" prop="uploadfile">
            <el-button class="centerBtn ml20" @click="FileUpload = true"
              >选择文件</el-button
            >
            <div>
              <el-upload :file-list="fileList" action="#" :on-remove="removeFile">
              </el-upload>
            </div>
          </el-form-item>
          <div style="padding-left: 100px; color: #606266" v-show="isNetworkDisk">
            注：若选择网盘上传的方式，请填写程序包查询路径
          </div>
          <el-form-item
            label="查看路径"
            label-width="100px"
            prop="netDiskUrl"
            v-show="isNetworkDisk"
          >
            <el-input
              class="centerCzInput"
              :disabled="!isNetworkDisk"
              v-model="auto_form.netDiskUrl"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button
          class="centerCzbtn mr20"
          type="button"
          @click="operation"
          :loading="AddLoading"
        >
          {{ isAdd ? "确认添加" : "确认修改" }}
        </el-button>
        <button class="centerBtn resetbtn" @click="CheckFile">取消</button>
      </div>
    </el-drawer>
    <!-- 文件上传 -->
    <el-drawer
      title="版本文件"
      :append-to-body="true"
      :visible.sync="FileUpload"
      :before-close="closefiledraw"
      ref="filedraw"
    >
      <div class="demo-drawer__content">
        <div class="importDv">
          <el-upload
            class="upload-demo"
            drag
            action="#"
            :auto-upload="false"
            :on-change="handleUpFile"
            :on-remove="removeFile"
            :file-list="fileList"
            :limit="isApp ? 1 : 3"
          >
            <img src="@/assets/images/upload.png" alt="" />
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip" v-if="currentPanel == 'cloud'">
              <span v-show="!isApp">
                需提交测试报告、版本程序包和部署指导手册 <br />
                文件命名规范：“测试报告”、“程序包”、“部署指导手册”（含关键字）<br />
                <!-- 测试用例为必须上传项，程序包不做限制<br /> -->
              </span>
              <span v-show="isApp">
                文件命名规范 "程序包"（含关键字）<br />
                文件类型规范 ".apk"<br />
              </span>
            </div>
            <div class="el-upload__tip" slot="tip" v-else>
              <span>
                需提交测试报告(<span style="color: red">必填</span>)、部署指导手册(<span
                  style="color: red"
                  >必填</span
                >)和版本程序包(选填) <br />
                文件命名规范：“测试报告”、“部署指导手册”、“程序包”（含关键字）<br />
              </span>
            </div>
          </el-upload>
          <div class="download">
            <el-button
              type="button"
              class="centerCzbtn"
              @click="upload"
              :loading="uploadLoading"
              >上传</el-button
            >
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 删除版本 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span>您当前正在执行删除版本操作,确认继续?</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="deleteVersion">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">取 消</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { _CarTypeAll } from "../../api/selCar";
import {
  _CouldFindAll,
  _AutoFindAll,
  _CouldVersionAdd,
  _AutoVersionAdd,
  _CouldVersionDelete,
  _AutoVersionDelete,
  _CouldVersionUpdate,
  _AutoVersionUpdate,
  _FileupLoad,
} from "../../api/versionManagement";
import { getTextWidth } from "../../until/helper";

export default {
  components: {
    Table, //Table组件
  },
  data() {
    return {
      tableData: [],
      cloud_columns: [
        {
          label: "产品端",
          prop: "productSideName",
        },
        {
          label: "产品类型",
          prop: "productTypeName",
        },
        {
          label: "版本号",
          prop: "versionNo",
        },
        {
          label: "版本内容",
          prop: "versionContent",
          width: "400",
          render: (h, params) => {
            // 去除换行
            let str = params.row.versionContent.replace(/[\r\n]/g, "");
            let width = getTextWidth(str, "el-table");
            if (width >= 290) {
              return h(
                "div",
                {
                  class: "version-row",
                },
                [
                  h(
                    "div",
                    {
                      class: "version-div",
                    },
                    str
                  ),
                  h(
                    "el-button",
                    {
                      class: "check-btn",
                      props: {
                        type: "text",
                      },
                      on: {
                        click: () => {
                          let wrapText = params.row.versionContent.replace(
                            /[\r\n]/g,
                            "<br/>"
                          );
                          this.viewMore(wrapText);
                        },
                      },
                    },
                    "查看详情"
                  ),
                ]
              );
            } else {
              return h("span", str);
            }
          },
        },
        {
          label: "发布时间",
          prop: "createTime",
          width: "150",
        },
        {
          label: "发布者",
          prop: "createName",
        },
        {
          label: "操作项",
          render: (h, params) => {
            return h("div", {}, [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.currentRow = params.row;
                      this.isAdd = false;
                      this.cloud_form.productSide = params.row.productSide;
                      this.cloud_form.productType = params.row.productType;
                      this.cloud_form.versionNo = params.row.versionNo;
                      this.cloud_form.versionContent = params.row.versionContent;
                      this.fileList = params.row.versionManagementAttachmentList.map(
                        (ele) => {
                          return {
                            name: ele.fileName,
                            attachmentId: ele.id,
                            status: "success",
                            isUpload: true,
                          };
                        }
                      );
                      this.cloud_drawer = true;
                      this.filelistshow = false;
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      this.currentRow = params.row;
                      this.delDia = true;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      auto_columns: [
        {
          label: "车辆类型",
          prop: "carTypeName",
        },
        {
          label: "车辆子类型",
          prop: "carTypeSonName",
        },
        {
          label: "版本号",
          prop: "versionNo",
        },
        {
          label: "版本内容",
          prop: "versionContent",
          width: "400",
          render: (h, params) => {
            // 去除换行
            let str = params.row.versionContent.replace(/[\r\n]/g, "");
            let width = getTextWidth(str, "el-table");
            if (width >= 290) {
              return h(
                "div",
                {
                  class: "version-row",
                },
                [
                  h(
                    "div",
                    {
                      class: "version-div",
                    },
                    str
                  ),
                  h(
                    "el-button",
                    {
                      class: "check-btn",
                      props: {
                        type: "text",
                      },
                      on: {
                        click: () => {
                          let wrapText = params.row.versionContent.replace(
                            /[\r\n]/g,
                            "<br/>"
                          );
                          this.viewMore(wrapText);
                        },
                      },
                    },
                    "查看详情"
                  ),
                ]
              );
            } else {
              return h("span", str);
            }
          },
        },
        {
          label: "发布时间",
          prop: "createTime",
          width: "150",
        },
        {
          label: "发布者",
          prop: "createName",
        },
        {
          label: "操作项",
          render: (h, params) => {
            return h("div", {}, [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.currentRow = params.row;
                      this.isAdd = false;
                      this.auto_form.carTypeArr = [
                        params.row.carTypeId,
                        params.row.carTypeSonId,
                      ];
                      this.auto_form.productType = params.row.productType;
                      this.auto_form.versionNo = params.row.versionNo;
                      this.auto_form.versionContent = params.row.versionContent;
                      this.auto_form.netDiskUrl = params.row.netDiskUrl;
                      this.fileList = params.row.versionManagementAttachmentList.map(
                        (ele) => {
                          return {
                            name: ele.fileName,
                            attachmentId: ele.id,
                            status: "success",
                            isUpload: true,
                          };
                        }
                      );
                      this.auto_drawer = true;
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      this.currentRow = params.row;
                      this.delDia = true;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loading: false,
      currentPanel: "cloud", //当前tab页
      currentRow: null, ////当前操作行

      cloud_drawer: false, //云平台添加编辑--Drawer
      auto_drawer: false, //自动车辆添加编辑--Drawer
      delDia: false, //删除--Dialog
      FileUpload: false, //文件上传--Drawer

      isAdd: true, //true添加，false编辑
      fileList: [], //文件上传列表
      fileftpURL: null, //文件上传地址
      uploadLoading: false,
      //fileTimer: null, //文件上传状态定时器
      filelistshow: true,

      //云平台添加编辑--form
      cloud_form: {
        attachmentId: "",
        productSide: "",
        productType: "",
        versionNo: "",
        versionContent: "",
      },
      //自动驾驶添加--form
      //carTypeArr: [],
      auto_form: {
        attachmentId: "",
        carTypeArr: [],
        carTypeId: "",
        carTypeSonId: "",
        versionNo: "",
        versionContent: "",
        netDiskUrl: "",
      },
      //添加编辑--rules
      rules: {
        productSide: [
          {
            required: true,
            message: "请选择产品端",
            trigger: "change",
          },
        ],
        productType: [
          {
            required: true,
            message: "请选择产品类型",
            trigger: "change",
          },
        ],
        carTypeArr: [
          {
            required: true,
            message: "请选择车辆子类型",
            trigger: "change",
          },
        ],
        versionNo: [
          {
            required: true,
            message: "请输入版本号",
            trigger: "blur",
          },
        ],
        versionContent: [
          {
            required: true,
            message: "请输入版本内容",
            trigger: "blur",
          },
        ],
        uploadfile: [
          {
            required: true,
            message: "文件必传",
            trigger: "change",
          },
        ],
        netDiskUrl: [
          {
            required: true,
            message: "请输入程序包路径",
            trigger: ["blur", "change"],
          },
        ],
      },
      AddLoading: false,

      selectCarType: [], //车辆类型选择value
      carTypeOption: [], //车辆类型数据源
      //车辆子类型设置
      carTypeOptionParams: {
        children: "children",
        label: "typeName",
        value: "id",
        checkStrictly: true,
      },
      //车辆子类型数据源
      carSubType: [],
      //产品端
      productlist: [
        { product: "东风悦享云服务平台", value: 1 },
        { product: "东风悦享数据分析平台", value: 2 },
        { product: "智能招车APP", value: 3 },
        { product: "安全员APP", value: 4 },
        { product: "CMS数据管理平台", value: 5 },
        { product: "自动驾驶工具链平台", value: 6 },
        { product: "台账管理平台", value: 7 },
      ],
      //产品类型
      producttypelist: [
        { producttype: "WEB", value: 3 },
        { producttype: "APP(安卓)", value: 1 },
      ],
      //分页和查询
      list: {
        page: 1,
        size: 10,
        productSide: null, //产品
        productType: null, //产品类型
        carTypeId: null, //车辆类型ID
        carTypeSonId: null, //车辆子类型ID
        startTime: null,
        endTime: null,
      },
      searchPage: {
        limit: 10,
        page: 1,
      },

      total: 0,
      date: [],
    };
  },
  mounted() {
    this.getTableData();
    this.getCarTypeOption();
  },
  methods: {
    /**
     * @desc 按照查询条件获取Table数据
     * @param pagination [Obejct] 分页设置
     */
    getTableData(pagination) {
      // 设置分页
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page;
          this.list.size = pagination.limit;
        }
      }
      // 设置日期
      if (this.date != null) {
        this.list.startTime = this.date[0];
        this.list.endTime = this.date[1];
      } else {
        this.list.startTime = "";
        this.list.endTime = "";
      }
      //设置车辆类型Id
      if (this.selectCarType) {
        if (this.selectCarType.length == 1) {
          this.list.carTypeId = this.selectCarType[0];
          this.list.carTypeSonId = null;
        } else {
          this.list.carTypeId = this.selectCarType[0];
          this.list.carTypeSonId = this.selectCarType[1];
        }
      }
      this.getList();
    },

    /**
     * @desc 按tab获取数据
     */
    async getList() {
      this.loading = true;
      if (this.currentPanel == "cloud") {
        //云平台获取版本数据请求
        let { success, message, data } = await _CouldFindAll(this.list);
        if (success == true) {
          this.tableData = data.list;
          this.total = data.total;
        } else {
          this.$message.error(message);
        }
      } else if (this.currentPanel == "auto") {
        //自动车辆获取版本数据请求
        let { success, message, data } = await _AutoFindAll(this.list);
        if (success == true) {
          this.tableData = data.list;
          this.total = data.total;
        } else {
          this.$message.error(message);
        }
      }
      this.loading = false;
    },

    /**
     * @desc 查询全部车辆类型--设置下拉框
     */
    async getCarTypeOption() {
      //设置查询的车辆类型数据源
      _CarTypeAll().then((res) => {
        if (res.success == true) {
          this.carTypeOption = res.data.list;
        }
      });
      //设置添加编辑的车辆子类型数据源
      _CarTypeAll().then((res) => {
        if (res.success == true) {
          this.carSubType = res.data.list;
          this.carSubType.forEach((e, i) => {
            this.carSubType[i].disabled = true;
          });
        }
      });
    },

    /**
     * @desc 重置查询条件和Table数据
     */
    reset() {
      this.list = {
        page: 1,
        size: 10,
        productSide: null, //产品ID
        productType: null, //产品类型ID
        carTypeId: null, //车辆类型ID
        carTypeSonId: null, //车辆子类型ID
        startTime: null,
        endTime: null,
      };
      this.total = 0;
      this.date = null;
      this.selectCarType = null;

      this.getList();
    },
    // 提交前文件操作
    beforeOperation() {
      let arrFile = [];
      let bcheck = true;
      if (this.fileList.length > 0) {
        // 检查文件规范
        if (!this.checkFileList()) {
          return false;
        }
        this.fileList.forEach((el) => {
          if (el.status != "success") {
            bcheck = false;
          }
          arrFile.push(el.attachmentId);
        });
      } else if (this.currentPanel == "cloud") {
        // 是cloud且产品不是安全员APP没传不做阻止
        if (this.isApp) {
          this.$message.error("文件未上传！");
          return false;
        }
      } else {
        this.$message.error("文件未上传！");
        return false;
      }
      if (!bcheck) {
        this.$message.error("上传未完成！");
        return false;
      }
      return arrFile;
    },
    /**
     * @desc 添加，编辑
     */
    operation() {
      if (this.isAdd) {
        // 检查文件是否上传完成
        let arrFile = this.beforeOperation();
        if (!arrFile) {
          return;
        }
        if (this.currentPanel == "cloud") {
          this.cloud_form.attachmentId = arrFile.toString();
          console.log("addcloud", this.cloud_form);
          this.AddLoading = true;
          _CouldVersionAdd(this.cloud_form)
            .then((res) => {
              if (res.success) {
                this.$refs.cloud_drawer.$message.success("添加成功");
                this.cloud_drawer = false;
                this.AddLoading = false;
              } else {
                this.$refs.cloud_drawer.$message.error(res.message);
                this.AddLoading = false;
              }
            })
            .catch(() => {
              this.AddLoading = false;
            });
        } else if (this.currentPanel == "auto") {
          if (!this.auto_form.netDiskUrl) {
            this.$message({
              message: "路径不能为空！",
              type: "error",
              customClass: "message-Zindex ",
            });
            return;
          }
          this.auto_form.attachmentId = arrFile.toString();
          let obj = JSON.parse(JSON.stringify(this.auto_form));
          delete obj.carTypeArr;
          if (this.auto_form.carTypeArr) {
            obj.carTypeId = this.auto_form.carTypeArr[0];
            obj.carTypeSonId = this.auto_form.carTypeArr[1];
          }
          console.log("addauto", obj);
          this.AddLoading = true;
          _AutoVersionAdd(obj)
            .then((res) => {
              if (res.success) {
                this.$refs.auto_drawer.$message.success("添加成功");
                this.auto_drawer = false;
                this.AddLoading = false;
              } else {
                this.$refs.auto_drawer.$message.error(res.message);
                this.AddLoading = false;
              }
            })
            .catch(() => {
              this.AddLoading = false;
            });
        }
      } else {
        // 云平台编辑
        if (this.currentPanel == "cloud") {
          let arrFile = this.beforeOperation();
          if (!arrFile) {
            return;
          }
          this.editCloud(arrFile);
        } else {
          let arrFile = this.beforeOperation();
          if (!arrFile) {
            return;
          }
          if (!this.auto_form.netDiskUrl) {
            this.$message({
              message: "路径不能为空！",
              type: "error",
              customClass: "message-Zindex ",
            });
            return;
          }
          this.editAuto(arrFile);
        }
      }
    },
    // 编辑确认（云平台）
    editCloud(arrFile) {
      this.cloud_form.attachmentId = arrFile.toString();
      let newObj = JSON.parse(JSON.stringify(this.cloud_form));
      newObj.id = this.currentRow.id;
      console.log("editcloud", newObj);
      this.AddLoading = true;
      _CouldVersionUpdate(newObj)
        .then((res) => {
          if (res.success) {
            this.$refs.cloud_drawer.$message.success("修改成功");
            this.cloud_drawer = false;
            this.AddLoading = false;
          } else {
            this.$refs.cloud_drawer.$message.error(res.message);
            this.AddLoading = false;
          }
        })
        .catch(() => {
          this.AddLoading = false;
        });
    },
    // 编辑确认（自动驾驶）
    editAuto(arrFile) {
      this.auto_form.attachmentId = arrFile.toString();
      let obj = JSON.parse(JSON.stringify(this.auto_form));
      obj.id = this.currentRow.id;
      delete obj.carTypeArr;
      if (this.auto_form.carTypeArr) {
        obj.carTypeId = this.auto_form.carTypeArr[0];
        obj.carTypeSonId = this.auto_form.carTypeArr[1];
      }
      this.AddLoading = true;
      this.AddLoading = true;
      _AutoVersionUpdate(obj)
        .then((res) => {
          if (res.success) {
            this.$refs.auto_drawer.$message.success("修改成功");
            this.auto_drawer = false;
            this.AddLoading = false;
          } else {
            this.$refs.auto_drawer.$message.error(res.message);
            this.AddLoading = false;
          }
        })
        .catch(() => {
          this.AddLoading = false;
        });
    },

    /**
     * @desc 添加，编辑关闭时清空数据
     */
    drawClose() {
      this.isAdd = true;
      this.fileList = [];
      this.fileftpURL = null;
      this.uploadLoading = false;

      this.cloud_form = {
        attachmentId: "",
        productSide: "",
        productType: "",
        versionNo: "",
        versionContent: "",
      };

      this.auto_form = {
        attachmentId: "",
        carTypeId: "",
        carTypeSonId: "",
        versionNo: "",
        versionContent: "",
        carTypeArr: [],
        netDiskUrl: "",
      };
      //(this.carTypeArr = []),
      // this.reset()
      this.getList();
    },

    /**
     * @desc 删除版本
     */
    async deleteVersion() {
      let versionid = this.currentRow.id;
      if (this.currentPanel == "cloud") {
        let { success, message, data } = await _CouldVersionDelete({
          id: versionid,
        });
        if (success) {
          this.$message.success("删除成功");
          this.delDia = false;
          this.reset();
        } else {
          this.$message.error(message);
        }
      } else if (this.currentPanel == "auto") {
        let { success, message, data } = await _AutoVersionDelete({
          id: versionid,
        });
        if (success) {
          this.$message.success("删除成功");
          this.delDia = false;
          this.reset();
        } else {
          this.$message.error(message);
        }
      }
    },

    /**
     * @desc 添加关闭之前，文件状态校验
     */
    CheckFile() {
      let fliestatus = false;
      this.fileList.forEach((el) => {
        if (el.status == "uploading") fliestatus = true;
      });
      if (fliestatus && this.currentPanel == "cloud") {
        this.$refs.cloud_drawer.$message.warning("文件正在上传！");
      } else if (fliestatus && this.currentPanel == "auto") {
        this.$refs.auto_drawer.$message.warning("文件正在上传！");
      }
      if (!fliestatus && this.currentPanel == "cloud") this.cloud_drawer = false;
      else if (!fliestatus && this.currentPanel == "auto") this.auto_drawer = false;
    },

    /**
     * @desc 添加文件
     * @param file [Object] 添加的文件
     * @param fileList [Object] 添加后的文件列表
     */
    handleUpFile(file, fileList) {
      if (this.isApp) {
        let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (fileSuffix == "apk") {
          this.fileList = fileList;
        } else {
          this.$message({
            message: "文件类型错误！",
            type: "error",
            customClass: "message-Zindex ",
          });
          this.fileList = [];
        }
      } else if (this.currentPanel == "auto") {
        if (
          file.name.indexOf("测试报告") != -1 ||
          file.name.indexOf("部署指导手册") != -1 ||
          file.name.indexOf("程序包") != -1
        ) {
          this.fileList = fileList;
        } else {
          this.$message({
            message: "文件命名错误！",
            type: "error",
            customClass: "message-Zindex ",
          });
          this.fileList = fileList.slice(0, fileList.length - 1);
        }
      } else {
        this.fileList = fileList;
      }
    },

    /**
     * @desc 移除文件
     * @param file [Object] 移除的文件
     * @param fileList [Object] 移除后的文件列表
     */
    removeFile(file, fileList) {
      this.fileList = fileList;
    },

    /**
     * @desc 上传文件
     */
    async upload() {
      if (this.checkFileList()) {
        this.filelistCheck();
        this.FileUpload = false;
      }
    },
    // 检查文件规范
    checkFileList() {
      //检查文件名是否合规
      //
      //云平台：测试用例为必须上传项，程序包不做限制 更新-全不做限制
      //自动驾驶：测试用例，部署指导手册必须上传项，程序包不做限制
      let filenamecheck = [false, false];
      if (this.currentPanel == "cloud") {
        filenamecheck[1] = true;
        if (this.fileList.length > 0) {
          filenamecheck[0] = true;
        }
      } else if (this.currentPanel == "auto") {
        this.fileList.forEach((el) => {
          if (el.name.indexOf("测试报告") != -1) filenamecheck[0] = true;
          if (el.name.indexOf("部署指导手册") != -1) filenamecheck[1] = true;
        });
      }
      if (!filenamecheck[0] || !filenamecheck[1]) {
        this.$message({
          message: "附件缺少，请上传！",
          type: "error",
          customClass: "message-Zindex ",
        });
        return false;
      }
      return true;
    },

    closefiledraw() {
      this.fileList = [];
      this.FileUpload = false;
    },

    /**
     * @desc 文件上传后查询上传状态
     */
    filelistCheck() {
      let timestamp = Date.now().toString();

      this.fileList.forEach((el) => {
        if (el.isUpload) {
          return;
        }
        let fileslicesize = 1000 * 1000;
        let slicetotal = +(el.size / fileslicesize + 1).toFixed(0);
        let current = 0;

        el.fileTimer = setInterval(async () => {
          let bTime = true;
          let formData = new FormData();
          let slicefile = el.raw.slice(
            current * fileslicesize,
            (current + 1) * fileslicesize
          );
          formData.append("file", slicefile);
          formData.append("fileUrl", timestamp);
          formData.append("filename", el.name);
          formData.append("chunk", current + 1);
          formData.append("chunks", slicetotal);
          formData.append("totalSize", el.size);
          // 文件储存请求
          // async
          console.log(current, slicetotal);
          if (current < slicetotal) {
            bTime = false;
            _FileupLoad(formData).then((res) => {
              if (res.success) {
                if (el.status != "success") {
                  el.percentage = el.percentage + (fileslicesize / el.size) * 100;
                }
                if (el.percentage >= 100) {
                  el.status = "success";
                  bTime = true;
                } else el.status = "uploading";
                if (res.data) {
                  el.attachmentId = res.data;
                  if (this.currentPanel == "auto" && el.name.indexOf("程序包") != -1) {
                    this.auto_form.netDiskUrl = res.data;
                  }
                }
              }
            });
          }
          current++;
          if (bTime && el.status == "success") {
            console.log(this.fileList);

            clearInterval(el.fileTimer);
          }
        }, 1000);
      });
    },

    /**
     * @description tab 被选中时触发
     * @param currentpanel [Object] 被选中的标签 tab 实例
     */
    changeTabs(currentpanel) {
      if (currentpanel) this.currentPanel = currentpanel.name;

      this.reset();
    },
    /**
     * @name: viewMore
     * @msg:
     * @param {string} str 需要显示的文字
     */
    viewMore(str) {
      this.$msgbox({
        title: "版本内容",
        showConfirmButton: false,
        message: `<div>${str}</div>`,
        dangerouslyUseHTMLString: true,
        closeOnClickModal: false,
        customClass: "msgbox",
        callback: () => {},
      });
    },
  },
  computed: {
    // 判断安全员APP
    isApp: function () {
      if (this.cloud_form.productSide == 4) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否网盘上传程序包
    isNetworkDisk: function () {
      let is = true;
      this.fileList.forEach((ele) => {
        if (ele.name.indexOf("程序包") != -1) {
          is = false;
        }
      });
      return is;
    },
  },
  watch: {
    isNetworkDisk(newVal) {
      // 清空自动填写值
      if (newVal) {
        this.auto_form.netDiskUrl = "";
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/.el-input__inner {
  border: 0px;
}

/deep/.el-tabs__content {
  overflow: visible;
}

/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: 0px solid #e4e7ed;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0px solid #e4e7ed;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  //border-bottom-color: #f5f8fa;
  border-bottom: 0px solid #e4e7ed;
  border-left: 0px solid #e4e7ed;
  border-right: 0px solid #e4e7ed;
}

/deep/.el-textarea__inner {
  box-sizing: border-box;
  background: #f5f8fa;
  border: 0px solid white !important;
  border-radius: 0px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.importDv {
  width: 80%;
  height: 180px;
  margin: 0px auto;

  .download {
    display: flex;
    margin-top: 40px;
    justify-content: center;
  }

  .upload-demo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    /deep/ .el-upload-dragger {
      background-color: #f5f8fa !important;
      border: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .el-upload__tip {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .downTemplate {
        color: #1683d9;
        cursor: pointer;
      }
    }
  }
}
/deep/.version-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .version-div {
    max-width: 295px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  .check-btn {
    padding: 8px;
    border: 1px solid #409eff;
  }
}
</style>
<style>
.msgbox .el-message-box__content {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
