//计算2个时间相差多少分钟
export function DiffSeconds(beginTime, endTeim, isLast) {
  let objDate = new Date()
  let nYear = objDate.getFullYear()
  let nMonth = objDate.getMonth()
  let nDay = objDate.getDate()

  nDay = nDay < 10 ? '0' + nDay : nDay

  let sBeginDate = nYear + '-' + nMonth + '-' + nDay + ' ' + beginTime
  let sEndDate = nYear + '-' + nMonth + '-' + nDay + ' ' + endTeim

  let objBeginDate = new Date(sBeginDate)
  let objEndDate = new Date(sEndDate)

  let nBeginTimestamp = objBeginDate.getTime() / 1000
  let nEndTimestamp = objEndDate.getTime() / 1000
  // let nDiffTimestamp = (nEndTimestamp + beginOffset) - (nBeginTimestamp - endOffset);
  let nDiffTimestamp = nEndTimestamp - nBeginTimestamp

  // console.log(nDiffTimestamp);

  return Math.round(nDiffTimestamp)
}

//时间戳转日期
export function TimestampToDatetime(timestamp, showTime) {
  let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  let year = date.getFullYear(),
    month = ('0' + (date.getMonth() + 1)).slice(-2),
    sdate = ('0' + date.getDate()).slice(-2),
    hour = ('0' + date.getHours()).slice(-2),
    minute = ('0' + date.getMinutes()).slice(-2),
    second = ('0' + date.getSeconds()).slice(-2)

  let resultDate = year + '-' + month + '-' + sdate

  if (showTime === true) {
    resultDate += ' ' + hour + ':' + minute + ':' + second
  }
  return resultDate
}

//时间戳转小时分
export function TimestampToTime(timestamp, showTime) {
  let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  let hour = ('0' + date.getHours()).slice(-2),
    minute = ('0' + date.getMinutes()).slice(-2),
    second = ('0' + date.getSeconds()).slice(-2)

  return hour + ':' + minute + ':' + second
}

//检查数组元素是否包含值
export function inArray(search, arrData) {
  for (var i in arrData) {
    if (arrData[i] == search) {
      return true
    }
  }

  return false
}

/**
 * @desc 换算文件大小单位
 * @param {number} size 文件大小，单位B
 * @returns {string} 文件大小
 */
export function filesizeToString(size) {
  let filesize = ''
  if (size > 1024 * 1024 * 1024 * 1024)
    filesize = (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + 'TB'
  else if (size > 1024 * 1024 * 1024)
    filesize = (size / 1024 / 1024 / 1024).toFixed(2) + 'GB'
  else if (size > 1024 * 1024) filesize = (size / 1024 / 1024).toFixed(2) + 'MB'
  else if (size > 1024) filesize = (size / 1024).toFixed(2) + 'KB'
  else filesize = size + 'B'
  return filesize
}

/**
 * @name: getTextWidth
 * @msg: 获取文字宽度
 * @param {string} str 需要获取文字宽度的文字
 * @param {string} className 文字字体大小的className
 * @return {number} 文字宽度
 */
export function getTextWidth(str, className, fontSize) {
  let id = uuid()
  let width = 0
  let html = document.createElement('span')
  html.innerText = str
  className? html.className = className : ''
  fontSize? html.style.fontSize = `${fontSize}px` : ''
  html.id = id
  document.querySelector('body').appendChild(html)
  width = document.querySelector('#'+id).offsetWidth
  document.querySelector('#'+id).remove()
  return width
}

/**
 * 加密函数
 * @param str 待加密字符串
 * @returns {string}
 */
export function compileStr(str) {
  var c = String.fromCharCode(str.charCodeAt(0) + str.length)

  for (var i = 1; i < str.length; i++) {
    c += String.fromCharCode(str.charCodeAt(i) + str.charCodeAt(i - 1))
  }

  return encodeURIComponent(c)
}

/**
 * 解密函数
 * @param str 待解密字符串
 * @returns {string}
 */
export function unCompileStr(str) {
  str = decodeURIComponent(str)
  var c = String.fromCharCode(str.charCodeAt(0) - str.length)

  for (var i = 1; i < str.length; i++) {
    c += String.fromCharCode(str.charCodeAt(i) - c.charCodeAt(i - 1))
  }
  return c
}



function uuid() {
  var s = [];
  var hexDigits = "ABCDEFabcdef";
  for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}